<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

const globalStore = useGlobal()
</script>

<template>
  <TransitionRoot
    appear
    :show="globalStore.alertModelRef"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-50"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-[black]/60" />
      </TransitionChild>
      <div
        class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-end"
      >
        <div
          class="w-full p-4 text-black bg-white text-center shadow-lg"
        >
          <div class="flex justify-center pb-2">
            <div
              v-if="globalStore.alertObjectRef.type === 'success'"
              class="h-10 w-10 bg-primary rounded-full pt-1 content-center border-solid border-2 border-white"
              style="margin-top: -35px"
            >
              <Icon
                name="hugeicons:checkmark-circle-02"
                class="p-0 text-xl shrink-0 group-hover:!text-primary bg-white"
              />
            </div>
            <div
              v-if="globalStore.alertObjectRef.type === 'error'"
              class="h-10 w-10 bg-danger rounded-full pt-1 content-center border-solid border-2 border-white"
              style="margin-top: -35px"
            >
              <Icon
                name="hugeicons:cancel-02"
                class="p-0 text-xl shrink-0 group-hover:!text-primary bg-white"
              />
            </div>
          </div>

          <strong v-if="globalStore.alertObjectRef.title">{{ globalStore.alertObjectRef.title }}</strong>
          <p v-if="globalStore.alertObjectRef.message">
            {{ globalStore.alertObjectRef.message }}
          </p>
          <div class="z-[60] flex justify-center p-2">
            <button
              type="button"
              class="btn btn-primary text-md py-1 px-3"
              @click="globalStore.alertState(false)"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </transitionroot>
</template>

<style scoped>
  /* Estilos para la transición */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
